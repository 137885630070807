import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Workflows from "./containers/Workflows";
import Schedule from "./containers/Schedule";
import Detail from "./containers/Detail";

export default function Routes() {
  return (
    <Switch>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/">
        <Schedule />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/detail">
        <Detail />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/workflows">
        <Workflows />
      </AuthenticatedRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
