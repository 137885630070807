import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import Routes from "./Routes";
import { AppContext } from "./libs/contextLib";
import { Auth, Hub } from "aws-amplify";
import { Base64 } from 'js-base64';
import "./App.css";
import env from "react-dotenv";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticating: true,
      isAuthenticated: false,
      isAdmin: false,
      prevLocation: ""
    };
  }

  checkIsAdmin(user) {
    let isAdmin = false;
    //console.log("checkIsAdmin:" + user) 
    for (let index in user.signInUserSession.idToken.payload["cognito:groups"]) {
      let group = user.signInUserSession.idToken.payload["cognito:groups"][index]
      if (group === 'admin') {
        isAdmin = true;
        break;
      }
    }
    return isAdmin;
  }

  async checkUser() {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        //console.log("user: " + user);
        this.setState({ isAuthenticated: true, isAuthenticating: false, isAdmin: this.checkIsAdmin(user) });
      })
      .catch((error) => {
        setTimeout(() => {
          if (!this.state.isAuthenticated && this.state.isAuthenticating) {
            this.setState({ isAuthenticating: false });
          }
        }, 1500);
      });
  }

  componentDidMount() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          //console.log("EVENT: " + event + ";DATA:" + data);
          this.setState({ isAuthenticated: true, isAuthenticating: false, isAdmin: this.checkIsAdmin(data) });
          // this.setState({ user: data})
          break;
        case "signOut":
          //console.log("SignOut");
          this.setState({ isAuthenticated: false });
          // this.setState({ user: null })
          break;
        case 'customOAuthState':
          const prevUrl = Base64.decode(decodeURIComponent(data));
          //console.log("PREV URL HUB: " + prevUrl);
          this.setState({ prevLocation: prevUrl });
          break;
        default:
          break;
      }
    });
    this.checkUser();
  }

  getPrevLocation() {
    const prevLocation = this.state.prevLocation;
    if (prevLocation !== "") {
      setTimeout(() => this.setState({prevLocation: ""}), 100);
    }
    //console.log("PREV LOCATION: " + prevLocation);
    return prevLocation;
  }

  handleLogout() {
    this.setState({ isAuthenticating: true, prevLocation: "" });
    Auth.signOut();
  }

  render() {
    return (
      !this.state.isAuthenticating && (
        <div className="App container">
          <Navbar bg="light" expand="lg">
            <Navbar.Brand>LGSpark Monitor ({env.PROJECT})</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                className="justify-content-end"
                activeKey={window.location.pathname}
              >
                {this.state.isAuthenticated && (
                  <>
                    <Nav.Link href="/">Executions</Nav.Link>
                    <Nav.Link href="/workflows">Workflows</Nav.Link>
                    <Nav.Link href="#" onClick={() => this.handleLogout()}>
                      Logout
                    </Nav.Link>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <AppContext.Provider
            value={{ isAuthenticated: this.state.isAuthenticated, isAdmin: this.state.isAdmin, prevLocation: this.getPrevLocation() }}
          >
            <Routes />
          </AppContext.Provider>
        </div>
      )
    );
  }
}

export default App;
