import smcat from "state-machine-cat";

class SFNDefinition {
  constructor(isChild) {
    this.isChild = isChild;
    this.sfnStates = [];
  }

  addState(state) {
    this.sfnStates.push(state);
  }

  getDeclares() {
    let declares = "";
    for (let i = 0; i < this.sfnStates.length; i++) {
      let value = this.sfnStates[i].getDeclares();
      if (value.length > 0) {
        declares = declares.concat(value + ",");
      }
    }
    if (declares.length > 0) {
      declares = declares.substring(0, declares.length - 1);
      if (!this.isChild) {
        declares = declares.concat(";");
      }
    }
    return declares;
  }

  getDiagram() {
    let diagram = "";
    for (let i = 0; i < this.sfnStates.length; i++) {
      diagram = diagram.concat(this.sfnStates[i].getDiagram());
    }
    return diagram;
  }
}

class SFNState {
  constructor(name, stateData, eventsData, isChild, isExecution) {
    this.name = name;
    this.state = stateData;
    this.isChild = isChild;
    this.isExecution = isExecution;
    this.children = [];
    this.executions = [];
    this.eventsType = {
      Prepared: ["Scheduled"],
      Started: ["Started", "Entered", "Submitted"],
      Suceeded: ["Exited", "Suceeded"],
      Failed: ["Failed", "TimedOut", "Aborted"],
      CaughtError: [""],
    };
    if (isExecution) {
      this.lastEvent = this.initEvents(eventsData);
    } else {
      this.lastEvent = "";
    }
  }

  initEvents(eventsData) {
    // SE BUSCA EL ULTIMO EVENTO DEL ESTADO
    let lastId = 0;
    for (let i = 1; i < eventsData.length; i++) {
      let event = eventsData[i];
      for (let item in event) {
        if (item.endsWith("EventDetails") && event[item].name === this.name) {
          lastId = i;
        }
      }
    }
    // SE MAPEA EL ESTADO
    let lastState = "";
    if (lastId > 0) {
      if (eventsData[lastId].stateExitedEventDetails &&
          eventsData[lastId].stateExitedEventDetails.name !== "Error task" &&
          String(eventsData[lastId].stateExitedEventDetails.output).includes("error-info")) {
        lastState = "CaughtError";
      } else {
        for (let type in this.eventsType) {
          for (let i = 0; i < this.eventsType[type].length; i++) {
            if (eventsData[lastId].type.endsWith(this.eventsType[type][i])) {
              lastState = type;
              break;
            }
          }
          if (lastState.length > 0) {
            break;
          }
        }
      }
    }
    return lastState;
  }

  getType() {
    return this.state.Type;
  }

  addExecution(exec) {
    this.executions.push(exec);
  }

  addChildren(child) {
    this.children.push(child);
  }

  getDeclares() {
    let declares = "";
    if (this.getType() === "Parallel") {
      for (let i = 0; i < this.children.length; i++) {
        let value = this.children[i].getDeclares();
        if (value.length > 0) {
          declares = declares.concat(value + ",");
        }
      }
      declares = declares.concat('"' + this.name + '" {');

      let declaresChildren = "";
      for (let i = 0; i < this.children.length; i++) {
        declaresChildren = declaresChildren.concat(
          '"Group ' + i + '" {' + this.children[i].getDiagram() + "},"
        );
      }
      if (declaresChildren.length > 0) {
        declaresChildren = declaresChildren.substring(
          0,
          declaresChildren.length - 1
        );
        declaresChildren = declaresChildren.concat(";");
      }
      declares = declares.concat(declaresChildren);

      declares = declares.concat("}");
    } else if (this.isExecution) {
      // SE DECLARA LO QUE NO ES PARALELO PARA PONER COLOR
      declares = '"' + this.name + '"';
      if (this.lastEvent === "Prepared") {
        declares = declares.concat(' [color="blue"]');
      } else if (this.lastEvent === "Started") {
        if (this.getType() === "Fail") {
          declares = declares.concat(' [color="red"]');
        } else {
          declares = declares.concat(' [color="blue"]');
        }
      } else if (this.lastEvent === "Suceeded") {
        declares = declares.concat(' [color="green"]');
      } else if (this.lastEvent === "Failed") {
        declares = declares.concat(' [color="red"]');
      } else if (this.lastEvent === "CaughtError") {
        declares = declares.concat(' [color="orange"]');
      }
    }
    return declares;
  }

  getDiagram() {
    let diagram = "";
    if (this.getType() === "Choice") {
      let choices = this.state.Choices;
      for (let choice in choices) {
        diagram = diagram.concat(
          '"' + this.name + '" => "' + choices[choice].Next + '";'
        );
      }
    } else if (this.state.Type === "Fail") {
      diagram = diagram.concat('"' + this.name + '" => "final";');
    } else if (this.isChild) {
      diagram = diagram.concat(
        '"' +
          this.name +
          (this.state.Next ? '" => "' + this.state.Next + '";' : '";')
      );
      if (this.state.Catch) {
        for (let index in this.state.Catch) {
          let item = this.state.Catch[index];
          if (item.Next) {
            diagram = diagram.concat(
              '"' + this.name + '" => "' + item.Next + '";'
            );
          }
        }
      }
    } else {
      diagram = diagram.concat(
        '"' +
          this.name +
          '" => "' +
          (this.state.Next ? this.state.Next : "final") +
          '";'
      );
      if (this.state.Catch) {
        for (let index in this.state.Catch) {
          let item = this.state.Catch[index];
          if (item.Next) {
            diagram = diagram.concat(
              '"' + this.name + '" => "' + item.Next + '";'
            );
          }
        }
      }
    }
    return diagram;
  }
}

class SFNUtils {
  constructor(isExecution) {
    this.isExecution = isExecution;
  }

  getSfnDefinition = (jsonSfn, sfnEvents, isChild) => {
    let sfnDef = new SFNDefinition(isChild);
    let jsonStates = jsonSfn.States;
    for (let state in jsonStates) {
      let sfnState = new SFNState(
        state,
        jsonStates[state],
        sfnEvents,
        isChild,
        this.isExecution
      );
      if (sfnState.getType() === "Parallel") {
        let branches = jsonStates[state].Branches;
        for (let i = 0; i < branches.length; i++) {
          let sfnChild = this.getSfnDefinition(
            branches[i],
            sfnEvents,
            true,
            this.isExecution
          );
          sfnState.addChildren(sfnChild);
        }
      }
      sfnDef.addState(sfnState);
    }
    return sfnDef;
  };

  getSfnGraph = (jsonSfn, sfnEvents) => {
    let sfnDiagram = this.getSfnDefinition(jsonSfn, sfnEvents, false);
    let diagram = sfnDiagram.getDeclares(this.isExecution);
    diagram = diagram.concat('"initial" => "' + jsonSfn.StartAt + '";');
    diagram = diagram.concat(sfnDiagram.getDiagram());
    const lSVGInAString = smcat.render(diagram, {
      outputType: "svg",
      fitToWidth: true,
    });
    let svgImg = lSVGInAString.substring(lSVGInAString.search("<svg"));
    let i = svgImg.indexOf("width=");
    let j = svgImg.indexOf(" ", i);
    svgImg = svgImg.substring(0, i) + "width=\"100%\"" + svgImg.substring(j)
    i = svgImg.indexOf("height=");
    j = svgImg.indexOf(" ", i);
    svgImg = svgImg.substring(0, i) + "height=\"auto\"" + svgImg.substring(j)
    return svgImg;
  };
}

export default SFNUtils;
