import React from "react";
import { API } from "aws-amplify";
import { Tabs, Tab, Badge, Button, Modal, Form, Spinner, Alert, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import cronparser from "aws-cron-parser";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { dateFilter, selectFilter } from "react-bootstrap-table2-filter";
import { AppContext } from "../libs/contextLib";
import "./Schedule.css";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      execList: [],
      futureList: [],
      selectedSfnExec: {},
      executeInput: "",
      executing: false,
      executingError: false,
      executingErrorMsg: "",
      workflowFilterOptions: {},
      statusFilterOptions: {},
      isReady: false,
    };
  }

  async componentDidMount() {
    this.setState({ isReady: false });
    // SE OBTIENE LA LISTA DE WORKFLOWS
    let sfnListRaw = [];
    await API.get("sfn", "/sfn", {})
      .then((data) => {
        sfnListRaw = [...data.stateMachines];
      })
      .catch((err) => console.log("SFN: " + err));
    // SE FILTRAN LOS SFN QUE NO TIENE EL TAG CORRESPONDIENTE
    let sfnList = [];
    for (let i = 0; i < sfnListRaw.length; i++) {
      await API.get("sfn", "/sfn/" + sfnListRaw[i].stateMachineArn + "/tags", {})
        .then((response) => {
          for (let j = 0; j < response.tags.length; j++) {
            let tag = response.tags[j];
            if (tag["key"] == "lgspark_workflow" && tag["value"] == "true") {
              sfnList.push(sfnListRaw[i]);
              break;
            }
          }
        })
        .catch((err) => console.log("TAG: " + err.message));
    }
    sfnListRaw.length = 0;
    // ######################################################
    let workflowFilterOptions = {};
    let statusFilterOptions = { "NOT EXECUTED": "NOT EXECUTED" };
    // SE OBTIENEN LAS EJECUCIONES
    for (let i = 0; i < sfnList.length; i++) {
      workflowFilterOptions[sfnList[i].name] = sfnList[i].name;
      await API.get("sfn", "/sfn/" + sfnList[i].stateMachineArn + "/exec", {})
        // eslint-disable-next-line no-loop-func
        .then((data) => {
          sfnList[i].executions = [...data.executions];
          for (let j = 0; j < sfnList[i].executions.length; j++) {
            sfnList[i].executions[j]["sfnName"] = sfnList[i].name;
            statusFilterOptions[sfnList[i].executions[j].status] =
              sfnList[i].executions[j].status;
            // INCLUIR SI LA EJECUCION ES PLANIFICADA O NO
            sfnList[i].executions[j].scheduled = String(
              sfnList[i].executions[j].name
            ).includes("_");
          }
        })
        .catch((err) => console.log("EXEC: " + err.message));
      // SE OBTIENEN LOS EVENTOS ASOCIADOS A CADA EJECUCION
      sfnList[i].rules = [];
      let rules = [];
      await API.get("sfn", "/events/" + sfnList[i].stateMachineArn, {})
        // eslint-disable-next-line no-loop-func
        .then((data) => {
          rules = [...data.RuleNames];
        })
        .catch((err) => console.log("EXEC: " + err.message));
      for (let j = 0; j < rules.length; j++) {
        // console.log("OBTENIENDO REGLAS DEL WORKFLOW " + sfnList[i].stateMachineArn);
        await API.get("sfn", "/events/sfn/" + rules[j], {})
          // eslint-disable-next-line no-loop-func
          .then((data) => {
            sfnList[i].rules.push(data);
            let executions = sfnList[i].executions;
            for (let index in executions) {
              if (!executions[index].rules) {
                executions[index].rules = [];
              }
              executions[index].rules.push(data);
            }
          })
          .catch((err) => console.log("EXEC: " + err.message));
      }
    }
    // SE OBTIENE LA LISTA DE EJECUCIONES NO REALIZADAS HASTA HOY
    // console.log("CALCULANDO HUECOS Y EJECUCIONES FUTURAS");
    let endDate = new Date();
    endDate = endDate.setDate(endDate.getDate() - 14);
    endDate = this.getIntFromDate(endDate);
    let futureEndDate = new Date();
    futureEndDate = futureEndDate.setDate(futureEndDate.getDate() + 7);
    futureEndDate = this.getIntFromDate(futureEndDate);
    // let cronOptions = {
    //   iterator: true,
    //   utc: true,
    // };
    let execList = [];
    let futureList = [];
    for (let index in sfnList) {
      let sfn = sfnList[index];
      // console.log("WORKFLOW " + sfn.stateMachineArn);
      execList = execList.concat(sfn.executions);
      if (sfn.rules.length > 0) {
        // console.log("PROCESANDO REGLAS Y EJECUCIONES");
        try {
          for (let index in sfn.rules) {
            let rule = sfn.rules[index];
            if (rule.State === "ENABLED") {
              // let cronExpression = "0 " + rule.ScheduleExpression.substring(5, rule.ScheduleExpression.length - 1);
              let cronExpression = rule.ScheduleExpression.substring(5, rule.ScheduleExpression.length - 1);
              // let interval = cronparser.parseExpression(
              //   cronExpression,
              //   cronOptions
              // );
              let interval = cronparser.parse(cronExpression);
              let lastIndex = 0;
              // let iterDate = interval.prev().value.toDate();
              let today = new Date();
              let iterDate = cronparser.prev(interval, today);
              let iteration = this.getIntFromDate(iterDate);
              // SE OBTIENEN LAS EJECUCIONES PASADAS
              while (iteration != null  &&  iteration > endDate) {
                if (lastIndex >= sfn.executions.length) {
                  let newDate = iterDate.getTime() / 1000;
                  let newExec = {
                    rules: [rule],
                    name: "",
                    startDate: newDate,
                    stopDate: newDate,
                    sfnName: sfn.name,
                    stateMachineArn: sfn.stateMachineArn,
                    executionArn: "",
                    status: "NOT EXECUTED",
                    scheduled: true,
                  };
                  execList.push(newExec);
                } else {
                  for (let i = lastIndex; i < sfn.executions.length; i++) {
                    let exec = sfn.executions[i];
                    let execDate = this.getIntFromTimestamp(exec.startDate);
                    if (execDate === iteration) {
                      lastIndex = i + 1;
                      break;
                    } else if (execDate < iteration) {
                      lastIndex = i;
                      let newDate = iterDate.getTime() / 1000;
                      let newExec = {
                        rules: [rule],
                        name: "",
                        startDate: newDate,
                        stopDate: newDate,
                        sfnName: sfn.name,
                        stateMachineArn: sfn.stateMachineArn,
                        executionArn: "",
                        status: "NOT EXECUTED",
                        scheduled: true,
                      };
                      execList.push(newExec);
                      break;
                    }
                  }
                }
                // iterDate = interval.prev().value.toDate();
                iterDate = cronparser.prev(interval, iterDate);
                iteration = this.getIntFromDate(iterDate);
              }
              // SE OBTIENEN LAS EJECUCIONES FUTURAS DE LA REGLA
              // interval = cronparser.parseExpression(cronExpression, cronOptions);
              // iterDate = interval.next().value.toDate();
              iterDate = cronparser.next(interval, today);
              iteration = this.getIntFromDate(iterDate);
              while (iteration != null  &&  iteration < futureEndDate) {
                let newDate = iterDate.getTime() / 1000;
                let newExec = {
                  rules: [rule],
                  name: "",
                  startDate: newDate,
                  stopDate: newDate,
                  sfnName: sfn.name,
                  stateMachineArn: sfn.stateMachineArn,
                  executionArn: "",
                  status: "PENDING",
                };
                futureList.push(newExec);
                iterDate = cronparser.next(interval, iterDate);
                iteration = this.getIntFromDate(iterDate);
              }
            }
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    // SE ORDENA LA LISTA DE EJECUCIONES POR FECHA DESCENDENTE
    // console.log("ORDENANDO EJECUCIONES");
    execList.sort((a, b) => {
      let aDate = this.getIntFromTimestamp(a.startDate);
      let bDate = this.getIntFromTimestamp(b.startDate);
      return bDate - aDate;
    });
    futureList.sort((a, b) => {
      let aDate = this.getIntFromTimestamp(a.startDate);
      let bDate = this.getIntFromTimestamp(b.startDate);
      return aDate - bDate;
    });
    // SE ACTUALIZA EL ESTADO
    // console.log("ACTUALIZANDO ESTADO");
    this.setState({
      // sfnList: sfnList,
      execList: execList,
      futureList: futureList,
      workflowFilterOptions: workflowFilterOptions,
      statusFilterOptions: statusFilterOptions,
      isReady: true,
    });
  }

  getIntFromDate = (date) => {
    if (date == null) {
      return null;
    } else {
      return moment(date).format("YYYYMMDDHHmm") * 1;
    }
  };

  getIntFromTimestamp = (timestamp) => {
    return moment.unix(timestamp).format("YYYYMMDDHHmm") * 1;
  };

  getExecScheduled = (status) => {
    if (status) {
      return "YES";
    } else {
      return "NO";
    }
  };

  getSfnBadgeStatus = (status) => {
    let variant = "";
    if (status === "SUCCEEDED") {
      variant = "success";
    } else if (status === "RUNNING") {
      variant = "primary";
    } else if (status === "NOT EXECUTED") {
      variant = "warning";
    } else if (status === "PENDING") {
      variant = "secondary";
    } else {
      variant = "danger";
    }
    return (
      <Badge pill variant={variant}>
        {status}
      </Badge>
    );
  };

  showExecute = async (sfn) => {
    this.setState({ executingError: false, selectedSfnExec: sfn });
    if (sfn.status !== "PENDING" && sfn.status !== "NOT EXECUTED") {
      await API.get("sfn", "/exec/" + sfn.executionArn, {})
        .then((data) => {
          this.setState({ executeInput: data.input });
        })
        .catch((err) => {
          this.setState({ executeInput: "" });
          console.log("EXEC: " + err.message);
        });
    } else {
      this.setState({ executeInput: "" });
    }
    this.setState({ showExecute: true });
  };

  hideExecute = () => {
    this.setState({ executingError: false, showExecute: false });
  };

  submitExecute = async (e) => {
    e.preventDefault();
    this.setState({ executingError: false, executing: true });
    let inputBody = this.state.executeInput.trim();
    if (inputBody.length > 0) {
      inputBody = JSON.parse(inputBody);
    }
    await API.post(
      "sfn",
      "/sfn/" + this.state.selectedSfnExec.stateMachineArn + "/exec",
      {
        body: inputBody,
      }
    )
      .then((data) => {
        this.setState({ showExecute: false });
      })
      .catch((err) => {
        this.setState({ executingError: true, executingErrorMsg: err.message });
        console.log(err);
      });
    this.setState({ executing: false });
  };

  dateFormatter = (cell, row) => {
    if (cell instanceof Date && !isNaN(cell)) {
      return <Moment format="DD/MM/YYYY HH:mm:ss">{cell}</Moment>;
    } else {
      return "";
    }
  };

  getExecutedTableHeader = () => {
    let header = [
      {
        dataField: "execStart",
        text: "Execution start",
        filter: dateFilter(),
        formatter: this.dateFormatter,
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "execEnd",
        text: "Execution end",
        formatter: this.dateFormatter,
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "workflow",
        text: "Workflow",
        filter: selectFilter({
          options: this.state.workflowFilterOptions,
        }),
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "execId",
        text: "Execution ID",
        headerStyle: { verticalAlign: "middle", width: "230px" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "scheduled",
        text: "Scheduled",
        formatter: (cell) => this.getExecScheduled(cell),
        filter: selectFilter({
          options: { true: "YES", false: "NO" },
        }),
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell) => this.getSfnBadgeStatus(cell),
        filter: selectFilter({
          options: this.state.statusFilterOptions,
        }),
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "actions",
        text: "Actions",
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
    ];
    return header;
  };

  getFutureTableHeader = () => {
    let header = [
      {
        dataField: "execStart",
        text: "Execution start",
        filter: dateFilter(),
        formatter: this.dateFormatter,
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "workflow",
        text: "Workflow",
        filter: selectFilter({
          options: this.state.workflowFilterOptions,
        }),
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell) => this.getSfnBadgeStatus(cell),
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
      {
        dataField: "actions",
        text: "Actions",
        headerStyle: { verticalAlign: "middle" },
        style: { verticalAlign: "middle" },
      },
    ];
    return header;
  };

  getSfnList = (list) => {
    const listItems = list.map((item, value) => {
      let data = {
        execStart: moment.unix(item.startDate).toDate(),
        execEnd: moment.unix(item.stopDate).toDate(),
        workflow: item.sfnName,
        execId: (
          <Link
            to={{
              pathname: "/detail",
              search: "?sfnId=" + encodeURIComponent(item.stateMachineArn) + "&execId=" + encodeURIComponent(item.executionArn)
            }}
          >
            {item.name}
          </Link>
        ),
        status: item.status,
        scheduled: item.scheduled,
        actions: (
          <Button
            variant="warning"
            size="sm"
            onClick={() => this.showExecute(item)}
            disabled={!this.context.isAdmin}
          >
            Execute
          </Button>
        ),
      };
      return data;
    });
    return listItems;
  };

  render() {
    return (
      <div className="Schedule">
        <h2>Executions</h2>
        {this.state.isReady && (
          <Tabs defaultActiveKey="past" id="tab">
            <Tab eventKey="past" title="Executed">
              <BootstrapTable
                bootstrap4
                keyField="execStart"
                data={this.getSfnList(this.state.execList)}
                columns={this.getExecutedTableHeader()}
                pagination={paginationFactory({
                  showTotal: true,
                  sizePerPageList: [
                    { text: "5", value: 5 },
                    { text: "10", value: 10 },
                    { text: "25", value: 25 },
                    { text: "50", value: 50 },
                  ],
                })}
                filter={filterFactory()}
                filterPosition="top"
                striped
                hover
                condensed
                noDataIndication="Table is Empty"
              />
            </Tab>
            <Tab eventKey="future" title="Scheduled">
              <BootstrapTable
                bootstrap4
                keyField="execStart"
                data={this.getSfnList(this.state.futureList)}
                columns={this.getFutureTableHeader()}
                pagination={paginationFactory({
                  showTotal: true,
                  sizePerPageList: [
                    { text: "5", value: 5 },
                    { text: "10", value: 10 },
                    { text: "25", value: 25 },
                    { text: "50", value: 50 },
                  ],
                })}
                filter={filterFactory()}
                filterPosition="top"
                striped
                hover
                condensed
                noDataIndication="Table is Empty"
              />
            </Tab>
          </Tabs>
        )}
        {!this.state.isReady && (
          <div className="d-flex justify-content-center">
            <Image src="/Loading_icon.gif"/>
          </div>
        )}
        <Modal show={this.state.showExecute} size="lg">
          <Form onSubmit={(e) => this.submitExecute(e)}>
            <Modal.Header>
              <Modal.Title>
                {this.state.selectedSfnExec.sfnName} - Execute workflow
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Input JSON</Form.Label>
                <Form.Control
                  id="jsonInput"
                  as="textarea"
                  placeholder="Enter input"
                  value={this.state.executeInput}
                  onChange={(e) =>
                    this.setState({ executeInput: e.target.value })
                  }
                />
                <Form.Text className="text-muted">
                  Input in JSON format for execution (can be empty)
                </Form.Text>
              </Form.Group>
              <Alert variant="danger" show={this.state.executingError}>
                {this.state.executingErrorMsg}
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.hideExecute()}>
                Close
              </Button>
              <Button
                variant="danger"
                type="submit"
                disabled={this.state.executing}
              >
                {this.state.executing && <Spinner animation="border" />} Execute
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
Schedule.contextType = AppContext;

export default Schedule;
