import React from "react";
import { Auth } from "aws-amplify";
import { Button } from "react-bootstrap";
import { queryString } from "../libs/Utilitites";
import "./Login.css";

class Login extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div className="Login">
        {/* <h3>LGSpark Workflows Monitor</h3> */}
        <Button variant="secondary" onClick={() => {
            const prevLocation = decodeURIComponent(queryString("redirect"));
            console.log("LOGIN: " + prevLocation);
            Auth.federatedSignIn({ customState: prevLocation });
          }}>
          Login
        </Button>
      </div>
    );
    }
}

export default Login;