import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { Base64 } from 'js-base64';

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated, prevLocation } = useAppContext();
  //console.log("AuthenticatedRoute PREVLOCATION: " + prevLocation);
  //console.log("AuthenticatedRoute PATHNAME: " + pathname);
  //console.log("AuthenticatedRoute SEARCH: " + search);
  const query = encodeURIComponent(Base64.encode(pathname + search));
  //console.log("AuthenticatedRoute QUERY: " + query);
  return (
    <Route {...rest}>
      {isAuthenticated ? (
        prevLocation !== "" ? ( <Redirect to={`${prevLocation}`} /> ) : children
      ) : (
        <Redirect to={`/login?redirect=${query}`} />
      )}
    </Route>
  );
}
