import env from "react-dotenv";

export default {
  Auth: {
    mandatorySignIn: true,
    region: "eu-west-1",
    userPoolId: env.USER_POOL_ID,
    identityPoolId: env.IDENTITY_POOL_ID,
    userPoolWebClientId: env.USER_POOL_WEBCLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "sfn",
        region: "eu-west-1",
        endpoint: env.API_ENDPOINT,
        custom_header: async () => {
          return {
            "Content-Type" : "application/json"
          }
        }
      },
    ]
  }
};
